
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { LinksObject } from '@/store/modules/Assets/LinksModule';
  
  import * as Yup from 'yup';

  const icon = ref('/media/avatars/blank-image.jpg');
  const selectedGov = ref<number>(0);

  export default defineComponent({
    name: 'link-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const selectedFile = ref<File | null>(null);

      const goBack = () => {
        router.back();
      };

      const linkSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('EN_NAME_IS_REQUIRED_FIELD'))
          .label('English Name'),
        titleAr: Yup.string()
          .required(() => translate('AR_NAME_IS_REQUIRED_FIELD'))
          .label('Arabic Name'),
      });

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        if (values.sort) {
          const numericValue = values.sort.replace(/[^0-9.]/g, '');
          values.sort = Number(numericValue);
        }

          const link = await store.dispatch(Actions.CREATE_LINKS, {
            titleEn: values.titleEn,
            titleAr: values.titleAr,
            externalLink: values.externalLink
          });

          if (selectedFile.value) {
            const formData = new FormData();
            formData.append('image', selectedFile.value);

            await store.dispatch(Actions.UPLOAD_LINK_ICON, {
              data: formData,
              id: link.id,
            });
          }

        // await store.dispatch(Actions.CREATE_LINKS, values);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        const {icon, ...linkData} = values;


        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_LINK'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'link-listing' });
          });
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('linksCreating'), [
          translate('assets'),
        ]);
      });
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');
      await store.dispatch(Actions.GET_LINKS);

      return {
        selectedGov,
        links: computed(() => store.getters.lijnksList),
        onSubmitCreate,
        submitButton,
        translate,
        linkSchema,
        goBack,
        can,
      };
    },
  });
